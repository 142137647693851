module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/bruno/Documents/GitHub/ROK/src/layouts/layout.js"},"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":560,"quality":70}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ROK FISHING","short_name":"ROK","description":"ROK Fishing Performance","start_url":"/","lang":"fr","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"./static/favicon-rok.png","icon_options":{"purpose":"any maskable"},"localize":[{"start_url":"/en/","lang":"en","name":"ROK FISHING","short_name":"ROK","description":"ROK Fishing Performance"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6a8ab49c2f37165b9e99651ba13a0812"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
