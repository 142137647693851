import "./src/layouts/layout.css"

export { wrapPageElement } from "./src/wrap-page-element"

export const onServiceWorkerUpdateFound = () => {
    const showNotification = () => {
      Notification.requestPermission(result => {
          if (result === 'granted') {
              navigator.serviceWorker.ready.then(registration => {
                  registration.showNotification('Update', {
                      body: 'New content is available here !',
                      icon: '../logo320x100.png',
                      vibrate: [200, 100, 200, 100, 200, 100, 400],
                      tag: 'request',
                  })
              })
          }
      })
    }
  
    showNotification()
  }